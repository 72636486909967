import React from 'react'

import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import ContactUs from 'src/components/ContactUs'

const PricingPage = () => (
  <Layout>
    <SEO title="Contact Us"/>

    <ContactUs />
  </Layout>
)

export default PricingPage
