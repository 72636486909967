import React from 'react'
import Fade from 'react-reveal/Fade'

import { FeaturesHeroWave } from 'src/components/Svg'
import ContactUsForm from 'src/components/ContactUsForm'

import s from './contactUs.module.scss'

const ContactUs = () => {

  const handleSubmitForm = data => {
    // fetch('https://api.sendgrid.com/v3/mail/send', {
    //   method: 'post',
    //   headers: {
    //     'Authorization': 'Bearer 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'
    //   },
    //   body: {
    //     "personalizations": [
    //       {
    //         "to": [
    //           {
    //             "email": "john@example.com"
    //           }
    //         ],
    //         "subject": "Hello, World!"
    //       }
    //     ],
    //     "from": {
    //       "email": "from_address@example.com"
    //     },
    //     "content": [
    //       {
    //         "type": "text/plain",
    //         "value": "Hello, World!"
    //       }
    //     ]
    //   }
    // }).then(res => {
    //   res.json()
    // }).then(json => {
    //   console.log('json -> ', json)
    // }).catch(err => {
    //   console.log('err -> ', err)
    // })
    // console.log('data -> ', data)
  }

  return (
    <>
      <div className={s.heroSection}>
        <Fade top cascade>
          <FeaturesHeroWave className={s.heroIcon} />
        </Fade>
      </div>

      <ContactUsForm onSubmit={handleSubmitForm} />
    </>
  )
}

export default ContactUs
